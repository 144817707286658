import React from "react";
import Helmet from "../../components/common/Helmet";
import InternalPageTemplate from "../../components/layout/InternalTemplate";
import InternalIntroHeading from "../../components/headings/InternalIntroHeading";
import ParagraphCopy from "../../components/helpers/ParagraphCopy";
import Parallaxe from "../../components/ui/Parallaxe";
import BodySection from "../../components/contentSections/OneColumn/BodySection";
import CapabilitiesBlocks from "../../components/CapabilitesBlocks/CapabilitiesBlocks";

import pageInfo from "../config/scripts/pageInfo";

const About = (props) => {
  const hero = {
    title: "Visit Our Online Brittle Store!",
    image: "bg_image--5",
    marginBottom: "75",
  };

  const intro = {
    title: "About Big Dipper Food Company",
    marginBottom: "0",
    paragraph: [
      {
        id: 1,
        copy: "To this day, all of our products are still made with small-batch quality. We believe if we stay true to our roots, our products will be as good today as they were when Big Dipper Food Company was founded in 2005.",
      },
      {
        id: 2,
        copy: "We still use only the finest and freshest ingredients available to us to make all of our products and we’ve taken great strides to ensure our products come from a facility that promotes food safety and quality. All of our products are manufactured in Youngstown, OH in a facility that’s been certified under the guidelines by the Safe Quality Foods Institute (SQFI), which meets benchmarks set forth by the Global Food Safety Initiative (GFSI).",
      },
      {
        id: 3,
        copy: "This is a huge stride for a small, family owned business, and we hope it shows our customers how dedicated we are to producing only the finest quality products possible.",
      },
      {
        id: 4,
        copy: "We believe it’s our uncompromising dedication to quality that makes our company and our products truly distinctive. The way we see it, quality never goes out of style.",
      },
      { id: 5, copy: "Thanks for checking us out!" },
    ],
  };

  const parallax = {
    bgImgSrc:
      "/assets/images/about/big-dipper-food-company-brittle-contract-food-manufacturing-brittle-products-1.webp",
    bgImgAlt: "Cayten's brand Peanut Brittle Popcorn in a wood bowl in the foreground, and in it's packaging in the background.",
    mainCopy: '"The Way We See It, Quality Never Goes Out of Style."',
  };

  const ourHistory = {
    heading: {
      title: "Our History",
      paddingTop: "60",
      marginBottom: "20",
    },
    paragraph: [
      {
        id: 1,
        copy: "Big Dipper Food Company was founded by two longtime friends, John and Marty, in August of 2005. Both had many years of experience in the food industry, producing hand made products with old-fashioned value. Wanting to branch out together, they pulled out John’s family recipe for homemade peanut brittle and an old tradition was revived.",
      },
      {
        id: 2,
        copy: "The company began producing peanut brittle in small batches, each carefully watched and stirred by hand. Only quality ingredients were used, like sweet buttery spread, tapioca syrup, and fresh nuts. The distinctive round brittles were poured by hand into molds and quickly packaged to preserve all that wonderful flavor. Pecans, cashews, and almonds were added to the mix and several new “flavors” of brittle came to be.",
      },
      {
        id: 3,
        copy: "It wasn’t long after the company was founded, John’s creative mind was turning and brittle met popcorn. A new favorite was born: brittle popcorn, in several new flavors: peanut, cashew, and pecan brittle popcorn, and for those who don’t like nuts, a buttery caramel brittle popcorn was introduced to the line which contained no nuts.",
      },
      {
        id: 4,
        copy: "Realizing how tasty the brittle coated popcorn was, John spent more time in the kitchen until PopCrunch™ was developed adding ingredients, like creamy peanut butter to the mix to create “Popcorn with a Crunch”.",
      },
      {
        id: 5,
        copy: "The company plans to extend the PopCrunch™ line in the future, with other ingredients like almond butter and pretzels. We figure now is a good time to suggest following our social media so you can be the first to know about new PopCrunch™ products!",
      },
    ],
  };

  const cta = {
    mainText: "Need More Information?",
    smallText:
      "Contact Us For Any of Your Co-Manufacturing, Co-Packing or Wholesale Brittle Needs!",
    buttonSrc: pageInfo.contact.link,
    buttonCopy: pageInfo.contact.navTitle + " Us",
  };

  return (
    <>
      <Helmet
        metaTitle={pageInfo.about.meta.title}
        metaDesc={pageInfo.about.meta.description}
      />
      <InternalPageTemplate
        heroImg={hero.image}
        heroTitle={hero.title}
          
        heroMarginBottom={hero.marginBottom}
        ctaMainText={cta.mainText}
        ctaSmallText={cta.smallText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      >
        <InternalIntroHeading
          title={intro.title}
          marginBottom={intro.marginBottom}
        />
        <ParagraphCopy>
          {intro.paragraph.map((paragraph) => (
            <p key={paragraph.id}>{paragraph.copy}</p>
          ))}
        </ParagraphCopy>
        <Parallaxe
          bgImage={parallax.bgImgSrc}
          bgImageAlt={parallax.bgImgAlt}
          mainCopy={parallax.mainCopy}
          logo={parallax.logo}
          logoAlt={parallax.logoAlt}
        />
        <BodySection content={ourHistory} />
        <CapabilitiesBlocks />
      </InternalPageTemplate>
    </>
  );
};
export default About;
