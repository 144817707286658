import React, { createRef, useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

import style from "../Contact.module.scss";

const ContactForm = (props) => {
  const [recaptchaVerification, setRecaptchaVerification] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState();
  const { REACT_APP_SERVICE: eService, REACT_APP_TEMPLATE: eTemplate, REACT_APP_PUBLIC: ePublic, REACT_APP_RECAPTCHA: recaptcha } = process.env;
  const recaptchaRef = createRef();

  const onRecaptchaChange = (value) => {
    const recaptchaVerified = (value) => value.trim() !== '';
    if (recaptchaVerified) {
      setRecaptchaVerification(true);
    } else if (!recaptchaVerified) {
      setRecaptchaVerification(false);
    }
  };
  
  const inquirySubmitHandler = (event) => {
    
    const formErrorMessage = <>We apologize for the inconvenience - an error has occurred. Please retry filling out the form again. If this still does not work please call us at <a href="tel:18002748853" className={style.link}>1-800-BRITTLE</a> or email your inquiry to <a href="mailto:info@bigdipperfood.com" className={style.link}>Info@BigDipperFood.com</a>. Please also let us know this contact form is not working so we can better server our visitors in the future. Thanks so much!</> 
      
    if (!recaptchaVerification) {
      event.preventDefault();
      setSubmissionMessage("Please complete the reCaptcha verification above.")
    } else {
      event.preventDefault();
      emailjs
      .sendForm( 
        eService,
        eTemplate,
        event.target,
        ePublic
        )
        .then(({ status }) => {
          if (status === 200) {
            setSubmissionMessage("Your Message has been sent successfully. We will be in touch as soon as possible. Talk soon!");
          } else {
            setSubmissionMessage(formErrorMessage);
          }
        })
        .catch(error => {
          setSubmissionMessage(formErrorMessage);
        });
        event.target.reset();
        recaptchaRef.current.reset();
    };
 
  }


  return (
    <form action="" onSubmit={inquirySubmitHandler}>
      <div className="rn-form-group">
        <input type="text" name="first-name" placeholder="First Name" className="white" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="last-name" placeholder="Last Name" className="white" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="your-email" placeholder="Your Email" className="white" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Phone Number" className="white" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Your Message" className="white" required></textarea>
      </div>
      
      <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={recaptcha}
      onChange={onRecaptchaChange}
      />

      <div className="rn-form-group">
        <button className="rn-button-style--2 btn-solid-white mt--20" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" style={{ background: "white", color: "#005baa" }} >
          Submit
        </button>
      </div>
      <div className="rn-form-group">
        <p className="submission-message">{submissionMessage}</p>
        </div>
    </form>
  );
}
export default ContactForm;
