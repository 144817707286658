import React from "react";
import Helmet from "../../../components/common/Helmet";
import InternalPageTemplate from "../../../components/layout/InternalTemplate";
import InternalIntroHeading from "../../../components/headings/InternalIntroHeading";
import AllCapabilitiesContent from "../components/AllCapabilitiesContent";
import pageInfo from "../../config/scripts/pageInfo";

const AllCapabilities = (props) => {
  const hero = {
    image: "bg_image--4",
    title: "Our Capabilities",
    marginBottom: "75",
  };
  const intro = {
    title: "Brittle and Brittle Popcorn Food Manufacturing",
    marginBottom: "50",
  };
  const cta = {
    mainText: "Need More Information?",
    smallText:
      "Contact Us For Any of Your Co-Manufacturing, Co-Packing or Wholesale Brittle Needs!",
    buttonSrc: pageInfo.contact.link,
    buttonCopy: pageInfo.contact.navTitle + " Us",
  };
  return (
    <>
      <Helmet
        metaTitle={pageInfo.allCapabilities.meta.title}
        metaDesc={pageInfo.allCapabilities.meta.description}
      />
      <InternalPageTemplate
        heroImg={hero.image}
        heroTitle={hero.title}
        heroMarginBottom={hero.marginBottom}
        ctaMainText={cta.mainText}
        ctaSmallText={cta.smallText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      >
        <InternalIntroHeading
          title={intro.title}
          marginBottom={intro.marginBottom}
        />
        <AllCapabilitiesContent />
      </InternalPageTemplate>
    </>
  );
};

export default AllCapabilities;
