import React from "react";

import style from "./ParagraphCopy.module.scss";

const ParagraphCopy = (props) => {
  return (
    <div className="container">
      <div className="row">
        <div className={`${style.paragraph} ${props.className}`}>{props.children}</div>
      </div>
    </div>
  );
};

export default ParagraphCopy;
