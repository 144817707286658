import React from "react";

const MainButton = (props) => {
  return (
    <div className={`main-button ${props.position} mt--${props.mt} mb--${props.mb}`} type={props.type || "button"} onClick={props.onClick}>
      <a className="rn-button-style--2 btn-solid" href={props.link} target={props.target} rel={props.rel}>{props.children}</a>
    </div>
  );
};

export default MainButton;


// import React, { useState } from "react";
// import { Link } from "react-router-dom"

// const MainButton = (props) => {
//   const [internalLink, setInternalLink] = useState();

  
// const { color, children, link, linkType, onClick, mb, mt, position, rel, target, type } = props;

//   if (linkType === "internal") {
//     setInternalLink(true);
//   } else if (linkType === "external") {
//     setInternalLink(false)
//   }
//   return (
//     <div className={`main-button ${position} mt--${mt} mb--${mb}`} type={type || "button"} onClick={onClick}>
//       {internalLink ? <Link className={`rn-button-style--2 ${color === "blue" ? "btn-solid" : "btn-solid-white"}`} href={link} target={target} rel={rel}>{children}</Link> : <a className={`rn-button-style--2 ${color === "blue" ? "btn-solid" : "btn-solid-white"}`} href={link} target={target} rel={rel}>{children}</a>}
//     </div>
//   );
// };

// export default MainButton;
