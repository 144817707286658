import React from "react";
import Helmet from "../../components/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import InternalPageTemplate from "../../components/layout/InternalTemplate";
import InternalIntroHeading from "../../components/headings/InternalIntroHeading";
import ContactTwo from "./components/ContactTwo";
import pageInfo from "../config/scripts/pageInfo";
import GoogleMap from "../../components/GoogleMap/GoogleMap";


const Contact = (props) => {
  const hero = {
    title: "How Can We Assist You?",
    image: "bg_image--2",
    marginBottom: "0",
  };

  const intro = {
    title: "Contact Big Dipper Food Company",
    marginBottom: "50",
  }

  const cta = {
    mainText: "Buy Our Products!",
    smallText:
      "Our Cayten's brand of small-batch, all-natural, hand-stirred products are available for sale at our online store!",
    buttonSrc: pageInfo.store.link,
    buttonCopy: pageInfo.store.navTitle + "!",
  };

  return (
    <>
      <Helmet
        metaTitle={pageInfo.contact.meta.title}
        metaDesc={pageInfo.contact.meta.description}
      />
      <InternalPageTemplate
        heroTitle={hero.title}
        heroImg={hero.image}
        heroMarginBottom={hero.marginBottom}
        ctaMainText={cta.mainText}
        ctaSmallText={cta.smallText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      >
        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area pt--60 pb--120 bg_color--5">
        <InternalIntroHeading title={intro.title} marginBottom={intro.marginBottom} />
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Call Us</h4>
                    <p>
                      <a href={pageInfo.phone.link}>{pageInfo.phone.number}</a>
                    </p>
                    <p>
                      <a href={pageInfo.phone.link}>(1-800-274-8853)</a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Us</h4>
                    <p>
                      <a href={pageInfo.email.link}>Info@BigDipperFood.com</a>
                      &nbsp;
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Locate Us</h4>
                    <p>
                      <a
                        href="https://www.google.com/maps/dir//Big+Dipper+Food+Company,+Inc.,+50+Superior+St,+Youngstown,+OH+44510"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        50 Superior Street <br />
                        Youngstown, Ohio 44510
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}
        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--2">
          <ContactTwo />
        </div>
        {/* End Contact Page Area  */}
        <GoogleMap />
      </InternalPageTemplate>
    </>
  );
};

export default Contact;
