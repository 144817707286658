import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HomeHeroSlider from "../../pages/home/components/HomeHeroSlider";
import InternalHero from "./InternalHero";

const Hero = (props) => {
  const [homepage, setHomepage] = useState();
  const pathname = useLocation().pathname;
  // Define hero
  useEffect(() => {
    const homeUrl = pathname === "/";
    if (homeUrl) {
      setHomepage(true)
    } else if (!homeUrl) {
      setHomepage(false);
    }
  }, [pathname]);

  return (
    <>
      {homepage ? <HomeHeroSlider /> : <InternalHero bgImage={props.bgImage} title={props.title} marginBottom={props.marginBottom} /> }
    </>
  );
};

export default Hero;
