import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainButtonWhite from "../ui/Buttons/MainButtonWhite";
import pageInfo from "../../pages/config/scripts/pageInfo";
import style from "./InternalHero.module.scss";

const InternalHero = (props) => {
  const [policyPage, setPolicyPage] = useState();
  const [aboutPage, setAboutPage] = useState();
  const pathname = useLocation().pathname;

  useEffect(() => {
    const policyPath = pathname.indexOf("policies") > -1;
    const aboutPath = pathname.indexOf("about") > -1;
    if (policyPath) {
      setPolicyPage(true);
    } else {
      setPolicyPage(false);
    }

    if (aboutPath) {
      setAboutPage(true);
    } else {
      setPolicyPage(false);
    }
  }, [pathname]);

  return (
    <section id="hero">
      <div
        className={`${
          !policyPage
            ? `breadcrumb-area rn-bg-color ptb--250 bg_image mb--${props.marginBottom} ${props.bgImage}`
            : `breadcrumb-area ${style["policy-hero"]} ptb--150 bg_image mb--200`
        } title={props.bgImageTitle}}`}
        data-black-overlay={!policyPage ? "3" : "2"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner pt--35">
                {!policyPage ? <h2 className="title">{props.title}</h2> : <h1 className={`title ${style["policy-heading"]}`}>{props.title}</h1>}
              </div>
              {aboutPage ? (
                <MainButtonWhite
                  position="center"
                  link={pageInfo.store.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  mt={`60`}
                  mb={`0`}
                >
                  {pageInfo.store.navTitle}!
                </MainButtonWhite>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InternalHero;
