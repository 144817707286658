import React from "react";
import { WiStars } from "react-icons/wi";
import MainButton from "../../../components/ui/Buttons/MainButton";

import style from "./ContentImage.module.scss";

const ContentImage = (props) => {

  return (
    <div
      className={`row sercice-details-content pb--140 align-items-center ${style["flex-col-rev"]}`}>
      <div className="col-lg-6 col-12">
        <div className="details mt_md--30 mt_sm--30">
          <h2 className={`title ${style["capability-title"]}`}>{props.title}</h2>
          <p className="description">{props.description}</p>
          <h4 className="mt--30">{props.listTitle}</h4>
          <ul className="list-style--1">
            {props.listItems.map((name, index) => {
              return (
                <li key={index}>
                  <WiStars size={30} /> {name}
                </li>
              );
            })}
          </ul>
          <MainButton link={props.buttonLink} mt={`40`} mb={`0`}>Contact Us</MainButton>
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <div className="thumb">
          <img className="w-100" src={props.imgSrc} alt={props.imgAlt} />
        </div>
      </div>
    </div>
  );
};
export default ContentImage;
