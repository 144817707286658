import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import pageInfo from "../../pages/config/scripts/pageInfo";

import style from "./Footer.module.scss";

const logoUrl = (
  <img
    src="/assets/images/logo/big-dipper-food-company-logo.webp"
    alt="Big Dipper Food Company Logo"
  />
);

const SocialShare = [
  { Social: <FaFacebookF size={28} />, socialLink: pageInfo.facebook.link },
  { Social: <FaInstagram size={28} />, socialLink: pageInfo.instagram.link },
];

const termsOfUse = (
  <a href={pageInfo.termsOfService.link}>{pageInfo.termsOfService.navTitle}</a>
);
const privacyPolicy = (
  <a href={pageInfo.privacyPolicy.link}>{pageInfo.privacyPolicy.navTitle}</a>
);

const Footer = () => {
  const websiteLiveYear = 2023;
  const [currentYear] = useState({ value: new Date().getFullYear() });

  const whatYear = () => {
    if (websiteLiveYear === currentYear.value) {
      return websiteLiveYear;
    } else {
      return `${websiteLiveYear}-${currentYear.value}`;
    }
  };

  return (
    <footer
      className={`${style["footer-area"]} ${style["footer-style-01"]} bg_color--3`} style={{backgroundImage: "url(/assets/images/pattern-6.png)"}}>
      <div className={`${style["footer-wrapper"]} pt--40 pb--35`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className={style["ft-text"]}>
                <div className={style.logo}>
                  <Link to={pageInfo.home.link}>{logoUrl}</Link>
                </div>
                <div className={style["ft-text"]}>
                  <div className={style.address}>
                    <address>
                      Big Dipper Food Company, Inc.
                      <br />
                      50 Superior Street
                      <br />
                      Youngstown, OH 44510
                    </address>
                  </div>
                </div>
              </div>
            </div>

            {/* Programs Widget  */}
            <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
              <div
                className={`${style["footer-link"]} ${style["right-col-devices"]}`}
              >
                <h4>Capabilities</h4>
                <ul className={style["ft-link"]}>
                  <li>
                    <Link to={pageInfo.allCapabilities.link}>
                      {pageInfo.allCapabilities.coManufacturing.navTitle}
                    </Link>
                  </li>
                  <li>
                    <Link to={pageInfo.allCapabilities.link}>
                      {pageInfo.allCapabilities.coPacking.navTitle}
                    </Link>
                  </li>
                  <li>
                    <Link to={pageInfo.allCapabilities.link}>
                      {pageInfo.allCapabilities.wholesaling.navTitle}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Programs Widget  */}

            {/* Start Company Widget  */}
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
              <div className={style["footer-link"]}>
                <h4>Company</h4>
                <ul className={style["ft-link"]}>
                  <li>
                    <Link to={pageInfo.about.link}>
                      {pageInfo.about.navTitle}
                    </Link>
                  </li>
                  <li>
                    <Link to={pageInfo.contact.link}>
                      {pageInfo.contact.navTitle}
                    </Link>
                  </li>
                  <li>
                    <a
                      href={pageInfo.store.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pageInfo.store.navTitle}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
              <div
                className={`${style["footer-link"]} ${style["right-col-devices"]}`}
              >
                <h4>Say Hello</h4>
                <ul className={style["ft-link"]}>
                  <li>
                    <Link to={pageInfo.contact.link}>
                      {pageInfo.contact.navTitle} Us
                    </Link>
                  </li>
                  <li>
                    <a href={pageInfo.phone.link}>
                      Phone: {pageInfo.phone.number}
                    </a>
                  </li>
                  <li>
                    <a href={pageInfo.email.link}>
                      Email: {pageInfo.email.address}
                    </a>
                  </li>
                </ul>
                <div className={`${style["social-share-inner"]} mt--20`}>
                  <ul
                    className={`social-share social-style--2 d-flex ${style["social-icons"]} liststyle`}
                  >
                    {SocialShare.map((val, i) => (
                      <li key={i}>
                        <a
                          href={`${val.socialLink}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {val.Social}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style["copyright-text"]}>
        <p> Copyright © {whatYear()} Big Dipper Food Company, Inc. All rights reserved. | {termsOfUse} | {privacyPolicy} | <a href="mailto:danpressly.digital@gmail.com">Site By Dan Pressly</a></p>
      </div>
    </footer>
  );
};
export default Footer;
