import React from "react";
import style from "./LocationMarker.module.scss";

const LocationMarker = (props) => {
  return (
    <div className={style.wrapper}>
      <span className={style.text}>{props.text}</span>
    </div>
  );
};

export default LocationMarker;
