import React from "react";

import style from "./PolicySection.module.scss";

const PolicySection = (props) => {
  return (
    <div className={`container pb--${props.marginBottom}`}>
      <div className="row">
        <div className="col-lg-12">
          {props.content.map((section) => {
            return (
              <div key={section.id} className="section-title service-style--3 mb--70">
                <h2 className={style.title}>{section.title}</h2>
                {section.copy.map((paragraph, i) => {
                  return (
                    <p key={i} className={style["policy-copy"]}>
                      {paragraph}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PolicySection;