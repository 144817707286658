import React from "react"
import GoogleMapReact from "google-map-react";
import LocationMarker from "./LocationMarker";

const GoogleMap = () => {
    const { REACT_APP_MAP: map } = process.env;
    const lat = 41.1165489;
    const lng = -80.6708153;
  
    const defaultProps = {
      center: {
        lat,
        lng
      },
      zoom: 12,
    };
    
    return(
        <div className="rn-contact-map-area position-relative">
          <div style={{ height: "650px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: map, language: "en", region: "US" }}
              defaultCenter={{ lat, lng }}
              defaultZoom={defaultProps.zoom}
              options={{minZoom: 10}}
            >
                
                <LocationMarker
                text="BD"
                lat={lat}
                lng={lng}
              />
            </GoogleMapReact>
          </div>
        </div>

    );
};

export default GoogleMap;