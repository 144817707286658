import React from "react";
import Helmet from "../../../components/common/Helmet";
import PolicyTemplate from "../components/PolicyTemplate";
import pageInfo from "../../config/scripts/pageInfo";
import pageContent from "../content/privacy";

const Privacy = (props) => {
  
  const hero = {
    title: "Privacy Policy",
  };

  const cta = {
    mainText: "Need More Information?",
    smallText:
      "Contact Us For Any of Your Co-Manufacturing, Co-Packing or Wholesale Brittle Needs!",
    buttonSrc: pageInfo.contact.link,
    buttonCopy: pageInfo.contact.navTitle + " Us",
  };

  return (
    <>
      <Helmet
        metaTitle={pageInfo.privacyPolicy.meta.title}
        metaDesc={pageInfo.privacyPolicy.meta.description}
      />
      <PolicyTemplate
        heroTitle={hero.title}
        // Content
        content={pageContent}
        // CTA
        ctaMainText={cta.mainText}
        ctaSmallText={cta.smallText}
        ctaButtonSrc={cta.buttonSrc}
        ctaButtonCopy={cta.buttonCopy}
      ></PolicyTemplate>
    </>
  );
};

export default Privacy;
