import React from "react";
import MainButton from "../../../components/ui/Buttons/MainButton";
import pageInfo from "../../config/scripts/pageInfo";

const HomeIntro = () => {
  return (
      <div className="about-area about-position-top pb--160 pb_sm--100">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center col-rev-mobile">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100 about-img-mobile-pt"
                    src="/assets/images/home/overlay/big-dipper-food-company-brittle-contract-food-manufacturing-hero-overlay.webp"
                    alt="Close-up of Cayten's brand Peanut Brittle pieces organized on a white, wood-looking background."
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h1 className="title">Our Products</h1>
                    <p className="description">
                      At Big Dipper Food Company, we sell only the finest
                      brittle and brittle popcorn products possible. Try out our
                      whole line up today! Learn all about the Brittle, Brittle
                      Popcorn and Beer Corn products we sell, contact us if you
                      would like to send praise or concern our way, and even
                      purchase products from our current (delicious) lineup.
                    </p>
                  </div>
                  <MainButton
                    link={pageInfo.store.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    position="left"
                    mt={`40`}
                    mb={`50`}
                  >
                    {pageInfo.store.navTitle}!
                  </MainButton>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Philosophy</h3>
                        <p>
                          A family-owned company committed to food safety and to
                          using only quality ingredients in our products.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Our Capabilities</h3>
                        <p>
                          We produce our own high-quality brittle products as
                          well as co-manufacture, co-pack, and wholesale our
                          clients' products.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default HomeIntro;
