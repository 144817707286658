import React from "react";
import { FiPackage, FiTruck, FiCheckSquare } from "react-icons/fi";
import pageInfo from "../../pages/config/scripts/pageInfo";

const servicesList = [
  {
    icon: <FiPackage />,
    title: pageInfo.allCapabilities.coManufacturing.navTitle,
    description:
      "We'll manufacture your brittle products from start to finish - raw ingredients to packaged products ready for shelves.",
    link: pageInfo.allCapabilities.link,
  },
  {
    icon: <FiCheckSquare />,
    title: pageInfo.allCapabilities.coPacking.navTitle,
    description:
      "Provide your finished products and packaging, and we'll take them to the finish line - ready to sell.",
    link: pageInfo.allCapabilities.link,
  },
  {
    icon: <FiTruck />,
    title: pageInfo.allCapabilities.wholesaling.navTitle,
    description:
      "Our SQFI certified production facility is capable of outputting 1000's of pounds of high-quality, hand-crafted brittle products daily.",
    link: pageInfo.allCapabilities.link,
  },
];

const ServiceList = (props) => {
  const { column, item } = props;
  const ServiceContent = servicesList.slice(0, item);

  return (
    <div className="row">
      {ServiceContent.map((val, i) => (
        <div className={`${column}`} key={i}>
          <a href={val.link}>
            <div className="service service__style--2">
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h3 className="title">{val.title}</h3>
                <p>{val.description}</p>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};
export default ServiceList;