import React from "react";

const InternalIntroHeading = (props) => {
  return (
    <div className={`container pb--${props.marginBottom}`}>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="section-title text-center service-style--3 mb--30">
              <h1 className="title">{props.title}</h1>
              <p>
                {props.description}
               </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalIntroHeading;
