import React from "react";
import InternalPageTemplate from "../../../components/layout/InternalTemplate";
import PolicySection from "./PolicySection";

const PolicyPageTemplate = (props) => {
  return (
    <InternalPageTemplate
      heroTitle={props.heroTitle}
      ctaMainText={props.ctaMainText}
      ctaSmallText={props.ctaSmallText}
      ctaButtonSrc={props.ctaButtonSrc}
      ctaButtonCopy={props.ctaButtonCopy}
    >
      <PolicySection content={props.content} />
      {props.children}
    </InternalPageTemplate>
  );
};

export default PolicyPageTemplate;
