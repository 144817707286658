import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import MainNavigation from "./MainNavigation";

const Header = () => {
  const [policyPage, setPolicyPage] = useState(false);
  const pathname = useLocation().pathname;
  const [stickyNav, setStickyNav] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState("white");

  // Use pathname to set state to false if current page is the home or an interior page (a non-policy page)
  // or true if current page is a policy page
  useEffect(() => {
    const policyUrl = pathname.indexOf("policies");
    if (policyUrl > -1) {
        setPolicyPage(true);
    } else if (policyUrl < 1) {
      setPolicyPage(false);
    }

      // Set sticky nav on home and interior pages (non-policy pages)
    const handleScroll = () => {
      if (!policyPage && window.scrollY >= 60) {
        setStickyNav(true);
        setMenuIconColor("#005baa");
      } else if (!policyPage && window.scrollY < 60) {
        setStickyNav(false);
        setMenuIconColor("white");
      } 
    };
    window.addEventListener("scroll", handleScroll);

}, [pathname, policyPage]);

  const toggleMobileMenu = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
      <header className={`header-area formobile-menu header--fixed default-color ${stickyNav || policyPage ? "sticky" : ""}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">
                <img
                  className="horizontal-logo"
                  src="/assets/images/logo/big-dipper-food-company-logo.webp"
                  alt="Big Dipper Food Company Logo"
                />
              </a>
            </div>
          </div>
          <div className="header-right">
            <MainNavigation menuIconColor={`${policyPage ? "#005baa" : menuIconColor}`} stickyNav={stickyNav} closeMobileMenu={toggleMobileMenu}/>  
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-xl-none pl--20">
              <span onClick={toggleMobileMenu} className="menutrigger">
                <FiMenu stroke={menuIconColor} />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-xl-none">
              <span onClick={toggleMobileMenu} className="closeTrigger">
                <FiX stroke={"#005baa"} />
              </span>
            </div>
          </div>
        </div>
      </header>
  );
};
export default Header;
