import React from "react";
import InternalBodyHeading from "../../headings/InternalBodyHeading";
import ParagraphCopy from "../../helpers/ParagraphCopy";

import styles from "./BodySection.module.scss";

const BodySection = (props) => {
  return (
    <section>
      <div className={`${styles["blue-background"]} pb--60`}>
          <InternalBodyHeading
            title={props.content.heading.title}
            paddingTop={props.content.heading.paddingTop}
            marginBottom={props.content.heading.marginBottom}
            className="white-text"
          />
          <ParagraphCopy className="white-text">
            {props.content.paragraph.map((paragraph) => (
              <p key={paragraph.id}>{paragraph.copy}</p>
            ))}
          </ParagraphCopy>
        </div>
      </section>
  );
};

export default BodySection;
