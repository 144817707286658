import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './pages/config/scripts/serviceWorker';
// import TagManager from 'react-gtm-module';
// import tagManagerArgs from './pages/config/scripts/google/tagManagerArgs';



ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
// TagManager.initialize(tagManagerArgs);