import React from "react";
import ImageContent from "./ImageContent";
import ContentImage from "./ContentImage";
import pageInfo from "../../config/scripts/pageInfo";

const AllCapabilitiesContent = (props) => {
  const coManufacturing = {
    title: "Co-Manufacturing",
    imgSrc: "/assets/images/capabilities/big-dipper-food-company-brittle-contract-food-manufacturing-brittle-comanufacturing.webp",
    imgAlt: "Workers standing by machinery in food manufacturing facility.",
    description:
      "Content Coming soon... Please click the button below to contact us for your Brittle Co-Manufacturing needs. We look forward to hearing from you!",
    listTitle: "What We Can Do:",
    listItems: [
      "Coming Soon..",
    ],
    buttonLink: pageInfo.contact.link,
  };

  const coPacking = {
    title: "Co-Packing",
    description: "Content Coming soon... Please click the button below to contact us for your Co-Packing needs. We look forward to hearing from you!",
    listTitle: "Benefits include:",
    imgSrc: "/assets/images/capabilities/big-dipper-food-company-brittle-contract-food-manufacturing-brittle-copacking.webp",
    imgAlt: "Three custom packages fanned out on a white background.",
    listItems: [
      "Coming soon...",

    ],
    buttonLink: pageInfo.contact.link,
  };

  const wholesaling = {
    title: "Wholesaling",
    imgSrc: "/assets/images/capabilities/big-dipper-food-company-brittle-contract-food-manufacturing-brittle-wholesaling.webp",
    imgAlt: "Many boxes stacked together on a pallet in a warehouse.",
    description: "Content Coming soon... Please click the button below to contact us for your Brittle Wholesaling needs. We look forward to hearing from you!",
    listTitle: "Wholesaling Includes:",
    listItems: [
      "Coming soon...",
    ],
    buttonLink: pageInfo.contact.link,
  };

  return (
    <div className="rn-service-details bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="service-details-inner">
              <div className="inner">
                <ImageContent
                  imgSrc={coManufacturing.imgSrc}
                  imgAlt={coManufacturing.imgAlt}
                  title={coManufacturing.title}
                  description={coManufacturing.description}
                  listTitle={coManufacturing.listTitle}
                  listItems={coManufacturing.listItems}
                  buttonLink={coManufacturing.buttonLink}
                />
                <ContentImage
                  title={coPacking.title}
                  description={coPacking.description}
                  listTitle={coPacking.listTitle}
                  imgSrc={coPacking.imgSrc}
                  imgAlt={coPacking.imgAlt}
                  listItems={coPacking.listItems}
                  buttonLink={coPacking.buttonLink}
                />
                <ImageContent
                  imgSrc={wholesaling.imgSrc}
                  imgAlt={wholesaling.imgAlt}
                  title={wholesaling.title}
                  description={wholesaling.description}
                  listTitle={wholesaling.listTitle}
                  listItems={wholesaling.listItems}
                  buttonLink={wholesaling.buttonLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCapabilitiesContent;
