import React from "react";
const CallToAction = (props) => {
  return (
    <section id="cta">
      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>{props.mainText}</h2>
                <span>{props.smallText}</span>
                <a className="rn-button-style--2" href={props.buttonSrc}>
                  <span>{props.buttonCta}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
