import React from "react";
import { Parallax } from "react-parallax";

import style from "./Parallaxe.module.scss";

const Parallaxe = props => {
  return (
      <Parallax
        className="rn-counterup-area rn-paralax-counterup"
        bgImage={props.bgImage}
        bgImageAlt={props.bgImageAlt}
        strength={1000}
        bgImageStyle={{height: "1273px", width: "100%"}}
      >
        <div className="counterup-area ptb--250" data-black-overlay="3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb_sm--0" >
                  <h2 className={style['parallax-text']}>{props.mainCopy}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
  );
};

export default Parallaxe;
