import React from "react";
import { Helmet } from "react-helmet";

const MetaHelmet = (props) => {
  const { metaTitle, metaDesc } = props;
  return (
    <>
      <Helmet titleTemplate="%s | Big Dipper Food Company">
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc}/>
      </Helmet>
    </>
  );
};

export default MetaHelmet;
