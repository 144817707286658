import React from "react";

const MainButtonWhite = (props) => {
  return (
    <div className={`main-button ${props.position} mt--${props.mt} mb--${props.mb}`} type={props.type || "button"} onClick={props.onClick} >
      <a className="rn-button-style--2 btn-solid-white" href={props.link} target={props.target} rel={props.rel} > {props.children} </a>
    </div>
  );
};

export default MainButtonWhite;