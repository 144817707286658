import React from "react";
import ContactForm from "./ContactForm";


const ContactTwo = props => {
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title white-text">Contact Us</h2>
                                <p className="description white-text">Please use the form below for any retail product, co-manufacturing, co-packing or wholesaling questions you may have. We look forward to hearing from you!</p>
                            </div>
                            <div className="form-wrapper">
                            <ContactForm />

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/big-dipper-food-company-brittle-contract-food-manufacturing-brittle-products.webp" alt="Peanut brittle stacked in layers on a small, white dish with a red and black plaid hand-towel in the background."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
export default ContactTwo;