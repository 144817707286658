import React from "react";
import { NavLink } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

import style from "./MainNavigation.module.scss";
import pageInfo from "../../pages/config/scripts/pageInfo.js";

const MainNavigation = (props) => {
  const { closeMobileMenu } = props;
  const SocialShare = [
    {
      Social: <FaFacebookF size="24px" fill={props.menuIconColor} />,
      socialLink: pageInfo.facebook.link,
    },
    {
      Social: <FaInstagram size="24px" fill={props.menuIconColor} />,
      socialLink: pageInfo.instagram.link,
    },
  ];
  const activeMainNav = props.stickyNav ? "active-main-nav-sticky" : "active-main-nav";

  return (
    <>
      <nav className="mainmenunav d-lg-block">
        <ul className="mainmenu">
          <li>
            <NavLink
              exact
              to={pageInfo.home.link}
              className={style.class__sub}
              activeClassName={activeMainNav}
              onClick={closeMobileMenu}
            >
              {pageInfo.home.navTitle}
            </NavLink>
          </li>
          <li>
          <NavLink
              exact
              to={pageInfo.allCapabilities.link}
              replace
              activeClassName={activeMainNav}
              onClick={closeMobileMenu}
            >
              {pageInfo.allCapabilities.navTitle}
            </NavLink>
          </li>
          {/* <li className="has-droupdown">
            <NavLink
              exact
              to={pageInfo.allCapabilities.link}
              replace
              activeClassName={activeMainNav}
            >
              {pageInfo.allCapabilities.navTitle}
            </NavLink>
            <ul className="submenu active">
              <li>
                <NavLink
                  to={pageInfo.allCapabilities.coManufacturing.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allCapabilities.coManufacturing.navTitle}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={pageInfo.allCapabilities.coPacking.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allCapabilities.coPacking.navTitle}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={pageInfo.allCapabilities.wholesaling.link}
                  activeClassName={activeSubNav}
                >
                  {pageInfo.allCapabilities.wholesaling.navTitle}
                </NavLink>
              </li>
            </ul>
          </li> */}
          <li>
            <NavLink
              exact
              to={pageInfo.about.link}
              activeClassName={activeMainNav}
              onClick={closeMobileMenu}
            >
              {pageInfo.about.navTitle}
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={pageInfo.contact.link}
              activeClassName={activeMainNav}
              onClick={closeMobileMenu}
            >
              {pageInfo.contact.navTitle}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={style["social-share-inner"]}>
        <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
          {SocialShare.map((val, i) => (
            <li key={i}>
              <a
                href={`${val.socialLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {val.Social}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="header-btn">
        <a
          className="rn-btn"
          href={pageInfo.store.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{pageInfo.store.navTitle}</span>
        </a>
      </div>
    </>
  );
};

export default MainNavigation;
