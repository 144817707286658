import React from "react";
import ServiceList from "./ServiceList";

const CapabilitiesBlocks = () => {
  return (
    <div className="service-area creative-service-wrapper pt--100 pb--120 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
              <h2 className="title">Production Capabilities</h2>
              <p>
                In addition to making our "Out of this World" brittle products, we also provide co-manufacturing, co-packing and wholesaling services to our partners.
              </p>
            </div>
          </div>
        </div>
        <div className="row creative-service">
          <div className="col-lg-12">
            <ServiceList
              item="6"
              column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapabilitiesBlocks;