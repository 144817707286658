import React from 'react';
import { BrowserRouter, Switch, Route  } from 'react-router-dom';

import Home from '../home/Home';
import AllCapabilities from '../capabilities/pages/AllCapabilities';
// import CoManufacturing from "../capabilities/pages/CoManufacturing";
// import CoPacking from "../capabilities/pages/CoPacking";
// import Wholesaling from "../capabilities/pages/Wholesaling";
import About from '../about/About'
import Contact from "../contact/Contact"
import TermsOfService from "../policies/pages/TermsOfService";
import Privacy from "../policies/pages/Privacy";
import Error404 from "../404";

import Layout from '../../components/layout/Layout';
import PageScrollTop from './scripts/PageScrollTop';
import pageInfo from './scripts/pageInfo';
// import tagManagerArgs from '../../Scripts/Google/tagManagerArgs';
import '../../assets/scss/index.scss';

const Routes = () => {
    return(
        <BrowserRouter basename={'/'}>
                <Layout>
                    <PageScrollTop>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}${pageInfo.home.link}`} component={Home}/>
                            <Route exact path={`${process.env.PUBLIC_URL}${pageInfo.allCapabilities.link}`} component={AllCapabilities}/>
                            {/* <Route path={`${process.env.PUBLIC_URL}${pageInfo.allCapabilities.coManufacturing.link}`} component={CoManufacturing}/>
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.allCapabilities.coPacking.link}`} component={CoPacking}/>
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.allCapabilities.wholesaling.link}`} component={Wholesaling}/> */}
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.about.link}`} component={About}/>
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.contact.link}`} component={Contact}/>
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.termsOfService.link}`} component={TermsOfService}/>
                            <Route path={`${process.env.PUBLIC_URL}${pageInfo.privacyPolicy.link}`} component={Privacy}/>
                            <Route component={Error404}/>
                        </Switch>
                    </PageScrollTop>
                </Layout>
            </BrowserRouter>
        )
    };

    export default Routes;