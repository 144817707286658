import React from "react";
import Hero from "../hero/Hero";
import CallToAction from "../callaction/CallToAction";

const InternalTemplate = (props) => {
  return (
    <>
      <Hero
        bgImage={props.heroImg}
        title={props.heroTitle}
        marginBottom={props.heroMarginBottom}
      />
      <section id="content">
        {props.children}
      </section>
        <CallToAction
          mainText={props.ctaMainText}
          smallText={props.ctaSmallText}
          buttonSrc={props.ctaButtonSrc}
          buttonCta={props.ctaButtonCopy}
        />
      
    </>
  );
};

export default InternalTemplate;
